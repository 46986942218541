@font-face {
  font-family: "LeagueSpartan-Regular";
  src: url("../assets/fonts/Spartan-Regular.ttf");
}

@font-face {
  font-family: "LeagueSpartan-Light";
  src: url("../assets/fonts/Spartan-Light.ttf");
}

@font-face {
  font-family: "LeagueSpartan-Bold";
  src: url("../assets/fonts/Spartan-Bold.ttf");
}

@font-face {
  font-family: "LeagueSpartan-ExtraBold";
  src: url("../assets/fonts/Spartan-ExtraBold.ttf");
}

@font-face {
  font-family: "LeagueSpartan-SemiBold";
  src: url("../assets/fonts/Spartan-SemiBold.ttf");
}

* {
  font-family: "LeagueSpartan-Regular";
}

.header-logo {
  width: 80%;
  height: auto;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 20px;
  background-color: #000000b3 !important;
}

.navbar-brand {
  padding-top: 1px;
  padding-bottom: 1px;
}

.nav-btn-container {
  margin-left: 50px;
  margin-right: 10px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
}

/* nav btn start*/
.nav-btn {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  cursor: pointer;
  z-index: 1;
}

.nav-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 40px;
  background-color: #000000;
  z-index: -1;
  transition: 200ms;
}

.nav-btn::after {
  content: attr(data);
  font-size: 15px;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms;
}

.nav-btn:hover::before {
  opacity: 0%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.nav-btn:hover::after {
  color: black;
}

.nav-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

/* nav btn end */

.navbar-dark .navbar-nav .nav-link {
  font-size: 15px;
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.home-banner {
  background-image: url("../assets/images/home-banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.header-banner-contain {
  position: relative;
}

.static-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
}

.banner-white-text {
  color: #fff;
  font-family: "LeagueSpartan-ExtraBold";
  font-size: 45px;
}

.banner-para-content {
  color: #fff;
  font-size: 17px;
  font-family: "LeagueSpartan-Light";
}

/* yellow btn start*/
.yellow-btn {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  cursor: pointer;
  z-index: 1;
  font-weight: 900;
}

.yellow-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 40px;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  z-index: -1;
  transition: 200ms;
}

.yellow-btn::after {
  content: attr(data);
  font-size: 15px;
  color: #000;
  transition: 200ms;
}

.yellow-btn:hover::before {
  opacity: 100%;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #000000;
}

.yellow-btn:hover::after {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.yellow-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

/* yellow btn end */
.yellow-btn-award {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  border: 1px solid #ffc107;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  cursor: pointer;
  z-index: 1;
  color: #000000;
}

.yellow-btn-award:hover {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: #000;
  border: 1px solid #ffc107;
  cursor: pointer;
  z-index: 1;
  color: #ffc107;
}

.yellow-btn-award:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

/* black btn start*/
.black-btn {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: #000;
  cursor: pointer;
  z-index: 1;
}

.black-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 40px;
  background: #000;
  z-index: -1;
  transition: 200ms;
}

.black-btn::after {
  content: attr(data);
  font-size: 15px;
  color: #fff;
  transition: 200ms;
}

.black-btn:hover::before {
  opacity: 100%;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #000000;
}

.black-btn:hover::after {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.black-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

/* black btn end */

/* black btn trans start*/
.black-btn-trans {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: #00000000;
  cursor: pointer;
  z-index: 1;
}

.black-btn-trans::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 40px;
  background: #00000000;
  border: 1px solid #000;
  z-index: -1;
  transition: 200ms;
}

.black-btn-trans::after {
  content: attr(data);
  font-size: 15px;
  color: #000000;
  transition: 200ms;
}

.black-btn-trans:hover::before {
  opacity: 100%;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #000000;
}

.black-btn-trans:hover::after {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.black-btn-trans:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

/* black btn trans end */

.black-btn-trans-sold {
  padding: 13px 20px 10px;
  border: 1px solid #000;
  color: #000;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: #00000000;
  cursor: pointer;
  z-index: 1;
}

.day-count-section {
  background-color: #000000;
  position: fixed;
  width: 100%;
  z-index: 99;
  bottom: 0;
}

.gradient-text {
  background: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-family: "LeagueSpartan-ExtraBold";
  margin-bottom: 0;
}

.single-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px 0px;
}

.counter-text {
  font-family: "LeagueSpartan-Bold";
  color: #fff;
  margin-bottom: 0;
}

.text-animation-container {
  width: 100%;
  height: 70px;
  /* Adjust as needed */
  position: relative;
  overflow: hidden;
}

.textAnimationContent {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 47px;
  font-family: "LeagueSpartan-ExtraBold";
}

.textAnimation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.textAnimation.visible {
  opacity: 1;
}

.calender-icon {
  width: 25px;
  height: auto;
  margin-right: 4px;
}

.date-text {
  color: #000;
  font-size: 15px;
  font-weight: 900;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-location-banner {
  background-color: #fff;
  border-radius: 50px;
  position: relative;
}

.first-layer {
  background-color: #fff;
  border-radius: 50px;
  padding: 17px 20px 15px;
  position: relative;
  z-index: 99;
}

.second-layer {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  z-index: 10;
}

.third-layer {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
  width: 60%;
  height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgb(255 255 255 / 49%);
  border-radius: 50px;
  z-index: 1;
}

.date-location-container {
  width: 100%;
  width: 100%;
  margin-top: 30px;
}

.our-clients-container {
  background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%);
  rotate: 1.8deg;
  width: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: -5%;
  z-index: 2;
}

.our-clients-container-bg {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  rotate: 359deg;
  width: 106%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: -5%;
  position: absolute;

  z-index: -1;
  /* height: 100px;
  top: 0; */
  height: 110px;
  top: 5px;
}

.our-clients-container2 {
  background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%);
  height: 240px;
  rotate: 3deg;
  width: 106%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: -5%;
}

.our-clients-container3 {
  background: linear-gradient(180deg, #fef700 0%, #fef700 50.52%, #fef700 100%);
  height: 230px;
  rotate: 357deg;
  /* transform: rotate(-2.32deg); */
  width: 106%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: -5%;
}

.client-img {
  margin: 0px;
  width: 180px !important;
  height: auto;
}

.bg-gradient-container {
  /* background: linear-gradient(90deg, #fef700 0%, #f18301 100%); */
}

@media screen and (min-width: 1600px) {
  .scroll-rotate-overflow {
    padding: 3.1rem 0;
  }
}

@media screen and (max-width: 1600px) {
  .scroll-rotate-overflow {
    padding: 2.2rem 0;
  }
}

.scroll-rotate-overflow {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  overflow: hidden;
  overflow-y: clip;
}

.scroll-rotate-overflow-carousel {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  overflow: hidden;
  overflow-y: clip;
  padding-top: 18px;
  padding-bottom: 18px;
  /* background-image: url('../assets/images/carousel-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto; */
  position: relative;
  margin-top: -10px;
}

.para-content {
  text-align: justify;
  font-size: 15px;
}

.bg-text-stroke {
  margin: 0;
  font-size: 7em;
  -webkit-text-stroke: 1px rgb(225 225 225);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  font-family: "LeagueSpartan-Bold";
}

.stroke-text-container {
  position: relative;
}

.gradient-text-heading {
  background: linear-gradient(
    179.21deg,
    #000000 19.66%,
    #717070 43.21%,
    #000000 66.39%
  );
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  font-family: "LeagueSpartan-ExtraBold";
  text-transform: uppercase;
  position: absolute;
  top: 23%;
}

.gradient-text-heading-yellow {
  background: linear-gradient(90deg, #fef700 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  font-family: "LeagueSpartan-ExtraBold";
  text-transform: uppercase;
  position: absolute;
  top: 30%;
}

.significance-heading {
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 30px;
  text-transform: uppercase;
}

.significance-bg {
  background: none;
  cursor: pointer;
  -webkit-transition: background 2s;
  transition: background 2s;
}

.significance-bg:hover {
  background: linear-gradient(90deg, #fef700 0%, #f18301 100%);
}

.significance-bg-black {
  background: none;
  cursor: pointer;
}

.significance-bg-black:hover {
  background: #f18301;
  transition: 2s;
}

.sign-img {
  /* width: 100%;
  height: auto;
  margin-bottom: -25%;
  margin-top: -10%; */
  width: 350px;
  top: -13%;
  height: auto;
  position: absolute;
}

.sign-right {
  right: 5%;
}

.sign-left {
  left: 0;
}

.container-significance {
  transition: opacity 0.3s ease-in-out;
  rotate: 358deg;
}

.para-content-white {
  text-align: justify;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0px;
}

.signicance-section {
  position: relative;
}

.significance-heading-stroke {
  -webkit-text-stroke: 1px rgb(245, 245, 245);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 0px;
  font-family: "LeagueSpartan-ExtraBold";
}

.sign-head-stroke-black {
  -webkit-text-stroke: 1px rgb(0, 0, 0);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 0px;
  font-family: "LeagueSpartan-ExtraBold";
}

.three-deg {
  rotate: 5deg !important;
}

.one-deg {
  rotate: 359deg !important;
}

.tnds-bg-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tnds-img {
  width: 100%;
  height: auto;
}

.tnds-text-right {
  background-color: #fff;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.gradient-text-tnds {
  background: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-family: "LeagueSpartan-ExtraBold";
  font-size: 60px;
}

.home-content-bottom {
  margin-bottom: 10px;
}

.card-img {
  width: 100%;
  height: auto;
  padding: 9%;
}

.card-img-bg {
  position: relative;
  transition: 600ms;
}

.card-img-bg:hover {
  background-image: url("../assets/images/card-bg.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.card-content {
  /* padding: 10%;
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 100%;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  z-index: 2; */
  position: absolute;
  left: 15%;
  top: 36%;
  z-index: 2;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0 0 0 / 20%);
  width: 80%;
  margin-left: 10%;
  transition: 0.5s ease;
  opacity: 1;
  height: 70%;
  border-radius: 25px;
  margin-bottom: 11%;
}

.card-img-bg:hover .card-overlay {
  opacity: 0;
}

.card-content-number {
  background: none;
  color: #fff;
  font-size: 50px;
  font-family: "LeagueSpartan-ExtraBold";
}

.card-img-bg:hover > .card-content .card-content-number {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.grey-text-stroke {
  -webkit-text-stroke: 1px rgb(102 102 102) !important;
}

.digital-summit-bg {
  /* background-color: #000; */
  background-image: url("../assets/images/digital-summit-bg.webp");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 20% !important;
}

.speaker-card {
  border-radius: 30px;
  /* cursor: pointer; */
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 3px;
  transition: margin 300ms;
  transition-timing-function: ease;
  margin-bottom: 30px;
}

.speaker-card:hover {
  box-shadow: 0px -3px 4px 0px #00000040;
  margin: 0px;
  margin-bottom: 25px;
}

.speaker-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sm-icon {
  color: #fff;
  padding: 5px;
  font-size: 14px;
}

.sm-icon:hover {
  color: #f18301;
}

.speaker-social-media {
  background-color: #000;
  padding: 13px 15px 8px 15px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  text-align: center;
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  border-image-slice: 1;
  width: 100%;
}

.speaker-content-bg {
  padding: 15px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-image-slice: 1;
  border-top: 4px solid transparent;
  border-image: linear-gradient(188deg, #ffffff 0%, #ffffff 100%);
}

.speaker-white-text {
  margin-bottom: 0px;
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 13px;
}

.speaker-name {
  background: linear-gradient(
    179.21deg,
    #000000 19.66%,
    #717070 43.21%,
    #000000 66.39%
  );
  -webkit-background-clip: text;
  color: transparent;
  font-family: "LeagueSpartan-ExtraBold";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
  /* height: 40px; */
}

.speaker-content-bg .speaker-name {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}

.speaker-job {
  font-family: "LeagueSpartan-Bold";
  text-align: center;
  color: #555353;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 17px;
}

.speaker-data {
  color: #5a5a5a;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0px;
  line-height: 17px;
  font-family: system-ui;
  font-weight: 500;
}

.speaker-card:hover > .speaker-social-media {
  background-color: #ffffff;
  border-image: linear-gradient(188deg, #000000 0%, #000000 100%);
}

.speaker-card:hover
  > .speaker-social-media
  .speaker-flex
  .speaker-sm-icon
  .sm-icon,
.speaker-card:hover > .speaker-social-media .speaker-flex .speaker-white-text {
  color: #000;
}

.speaker-card:hover > .speaker-content-bg {
  background-color: #000000;
  border-top: 4px solid transparent;
  border-image: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  border-image-slice: 1;
}

.speaker-card:hover > .speaker-content-bg .speaker-name,
.speaker-card:hover > .speaker-content-bg .speaker-job,
.speaker-card:hover > .speaker-content-bg .speaker-data {
  color: #ffffff;
}

.sub-heading-attend {
  color: #606060;
  font-size: 22px;
  font-family: "LeagueSpartan-SemiBold";
  text-align: center;
  margin-bottom: 40px;
}

.wca-card-bg-yellow {
  background-image: url("../assets/images/bg-yellow.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 14rem;
  padding: 5% 2%;
  position: relative;
}

.wca-card-bg-color-black {
  padding: 5%;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(
    179.21deg,
    #000000 19.66%,
    #717070 43.21%,
    #000000 66.39%
  );
  transition: padding 3ms;
  transition-timing-function: ease-in-out;
}

.attend-card-text-white {
  color: #ffffff;
  font-family: "LeagueSpartan-Bold";
  margin-bottom: 0px;
  font-size: 23px;
}

.wca-card-bg-yellow:hover {
  background-image: url("../assets/images/bg-black.webp");
}

.wca-card-bg-yellow:hover > .wca-card-bg-color-black {
  background: linear-gradient(180deg, #fef700 0%, #fffb5f 52.5%, #fef700 100%);
}

.wca-card-bg-yellow:hover
  > .wca-card-bg-color-black
  .row
  .col-lg-7
  .attend-card-text-white {
  color: #000000;
}

.wca-img-anim {
  width: 230px;
  height: auto;
  position: absolute;
  right: 4%;
  bottom: 6%;
  transition: width 300ms;
  transition-timing-function: ease-in-out;
}

.wca-card-bg-yellow:hover > .wca-card-bg-color-black .wca-img-anim {
  width: 300px;
  right: 1%;
  bottom: 4%;
}

.card-img-bg-element {
  position: relative;
  border-radius: 40px;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 2px;
}

.card-content-element {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  padding: 30px;
}

.card-content-heading-element {
  background: none;
  color: #fff;
  font-size: 37px;
  font-family: "LeagueSpartan-ExtraBold";
}

.card-img-element {
  width: 100%;
  height: auto;
}

.card-img-bg-element:hover {
  border: 0px solid transparent;
  border-radius: 39px;
  background-image: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 2px;
}

.card-img-bg-element:hover
  > .card-content-element
  .card-content-heading-element {
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(188deg, #fce701 0%, #f18301 100%);
}

.element-bg {
  background-image: url("../assets/images/element-bg.webp");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 10% !important;
  padding-top: 7% !important;
}

.wca-card-bg-black {
  background-image: url("../assets/images/bg-black.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 14rem;
  padding: 5% 2%;
  position: relative;
}

.wca-card-bg-color-yellow {
  padding: 5%;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(180deg, #fef700 0%, #fffb5f 52.5%, #fef700 100%);
  transition: padding 3ms;
  transition-timing-function: ease-in-out;
}

.attend-card-text-black {
  color: #000000;
  font-family: "LeagueSpartan-Bold";
  font-size: 25px;
  margin-bottom: 0px;
}

.wca-card-bg-black:hover {
  background-image: url("../assets/images/bg-yellow.webp");
}

.wca-card-bg-black:hover > .wca-card-bg-color-yellow {
  background: linear-gradient(
    179.21deg,
    #000000 19.66%,
    #717070 43.21%,
    #000000 66.39%
  );
}

.wca-card-bg-black:hover
  > .wca-card-bg-color-yellow
  .row
  .col-lg-7
  .attend-card-text-black {
  color: #ffffff;
}

.wca-card-bg-black:hover > .wca-card-bg-color-yellow .wca-img-anim {
  width: 300px;
  right: 1%;
  bottom: 5%;
}

.influence-heading {
  font-size: 4em;
  -webkit-text-stroke: 1px rgb(232, 232, 232);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  font-family: "LeagueSpartan-Bold";
  margin-bottom: 0px;
}

.innovate-bg {
  background-color: #000;
}

.inspire-content {
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 25px;
}

.innovate-section {
  position: relative;
  padding-top: 8%;
}

.influece-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40rem;
  height: auto;
}

.footer-section {
  background-color: #000;
  border-radius: 40px;
  padding: 30px;
  position: relative;
}

.footer-icons {
  margin-bottom: 15px;
}

.header-banner-contain .footer-container {
  margin-bottom: 5%;
}

.footer-container {
  background-color: #ffffff;
  border: 2px solid #000;
  padding: 2px;
  border-radius: 40px;
}

.footer-icons span {
  color: #fff;
  font-size: 22px;
  padding: 10px;
}

.footer-icons span.youtube:hover {
  color: #ff0a22;
}
.footer-icons span.instagram:hover {
  color: #ff0a91;
}
.thnk-page-follow {
  text-decoration: none;
}
.thnk-page-follow:hover .gradient-text {
  color: #ff0a91;
}
.footer-icons span.twitter:hover {
  color: #0dcaf0;
}

.footer-icons span.linkedin:hover {
  color: #1a77ff;
}

.footer-logo {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.footer-heading {
  color: #fff;
  font-family: "LeagueSpartan-SemiBold";
  margin-bottom: 16px;
}

.footer-link-text {
  color: #ebebeb;
  font-size: 14px;
  text-decoration: none;
}

.footer-link-text:hover {
  color: #f8c200;
}

.footer-icons span:hover {
  color: #f8c200;
}

.footer-links ul li {
  margin-bottom: 6px;
}

.footer-se-logo {
  width: 270px;
  height: auto;
  position: absolute;
  right: 0px;
  top: 12%;
}

.why-attend-section {
  margin-bottom: 5px;
}

.why-attend-card-container {
  padding: 4%;
}

.why-attend-section:hover > .row .col-lg-8 .why-attend-card-container {
  background-image: url("../assets/images/bg-why-attend-card.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.why-attend-card-1 {
  cursor: pointer;
  background: radial-gradient(#111 50%, #000 100%);
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 120px;
}

.why-attend-card-1 .why-attend-img-1 {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  /* -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0); */
  margin-left: 0px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.why-attend-section:hover
  > .row
  .col-lg-8
  .why-attend-card-1
  .why-attend-img-1 {
  /* transform: scale(1.1) translateX(-20px); */
  opacity: 0.3;
  margin-left: 30px;
}

.overlay-why-attend {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 33px;
}

.text-p {
  color: #fff;
  font-size: 16px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  margin-bottom: 0px;
}

.why-attend-section:hover
  > .row
  .col-lg-8
  .why-attend-card-1
  .overlay-why-attend
  .text-p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.pos-ab-why {
  position: relative;
}

.ahy-attent-heading {
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 45px;
  text-transform: capitalize;

  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
}

.why-attend-section:hover > .row .col-lg-4 .ahy-attent-heading {
  background: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  font-size: 39px;
}

.ahy-attent-heading-2 {
  color: #000000;
  font-family: "LeagueSpartan-Bold";
  font-size: 39px;
  text-transform: capitalize;
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.why-attend-section:hover > .row .col-lg-4 .ahy-attent-heading-2 {
  background: linear-gradient(188deg, #ffffff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.hilton-section {
  background-image: url("../assets/images/hilton-bg.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 4%;
}

.hilton-img {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 43%;
  height: auto;
}

.hilton-black {
  font-family: "LeagueSpartan-ExtraBold";
  font-size: 60px;
  text-transform: uppercase;
  color: #000;
}

.hilton-stroke {
  font-size: 60px;
  -webkit-text-stroke: 1px rgb(49 48 48);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  font-family: "LeagueSpartan-Bold";
}

/* left to right */

.why-attend-card-1 .why-attend-img-2 {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  margin-left: 30px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.why-attend-section:hover
  > .row
  .col-lg-8
  .why-attend-card-1
  .why-attend-img-2 {
  margin-left: 0;
  opacity: 0.3;
}

.overlay-why-attend-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 33px;
}

.text-p-2 {
  color: #fff;
  font-size: 16px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  margin-bottom: 0px;
}

.why-attend-section:hover
  > .row
  .col-lg-8
  .why-attend-card-1
  .overlay-why-attend-2
  .text-p-2 {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.ahy-attent-heading-22 {
  color: #ffffff00;
  font-family: "LeagueSpartan-Bold";
  font-size: 45px;
  text-transform: capitalize;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
}

.why-attend-section:hover > .row .col-lg-4 .ahy-attent-heading-22 {
  background: linear-gradient(188deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  font-size: 39px;
}

.ahy-attent-heading-222 {
  color: #000000;
  font-family: "LeagueSpartan-Bold";
  font-size: 39px;
  text-transform: capitalize;
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.why-attend-section:hover > .row .col-lg-4 .ahy-attent-heading-222 {
  background: linear-gradient(
    188deg,
    #ffffff00 0%rgba (255, 255, 255, 0) ff 100%
  );
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

/* partner with us */

.partnerwithus-banner {
  background-image: url("../assets/images/partnerwithus-banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 38rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.pws-form {
  box-shadow: 0px 2px 4px 0px #00000040;
  border: 0px;
  line-height: 2.5;
  color: #000000;
  border-radius: 100px;
  padding: 0.375rem 1.5rem;
}

.partnerwithuscontent {
  position: relative;
  height: 16rem;
}

.partnerwithus-pos {
  position: absolute;
  top: -27%;
  width: 100%;
}

.fs-partner {
  font-size: 48px;
}

/* agenda */

.agenda-banner {
  background-image: url("../assets/images/agenda-banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 38rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.bg-agenda {
  background-color: #000;
}

.bg-agenda .our-clients-container-bg {
  z-index: 1;
}

.agenda-table {
  border-collapse: separate;
  border-spacing: 10px;
}

.rounded-border-heading {
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 0px 10px;
  text-align: center;
  background-color: #000 !important;
}

.table-heading {
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 16px;
  padding: 15px 0px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: -5px;
}

.w-time {
  width: 20%;
}

.w-topic {
  width: 35%;
}

.w-speaker {
  width: 45%;
}

.table-yellow-content {
  color: #fef700;
  font-size: 14px;
  padding: 15px 0px;
  text-transform: uppercase;
  margin-bottom: -5px;
}

.rounded-border-yellow {
  border: 1px solid #fef700;
  border-radius: 10px;
  padding: 0px 10px;
  text-align: center;
}

.rounded-border-content {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  text-align: center;
}
.rounded-border-content h4 {
  text-align: left;
}
.table-para-content {
  color: #c4c4c4;
  font-size: 14px;
  padding: 15px 15px;
  text-transform: capitalize;
  margin-bottom: -5px;
}

.font-bold {
  font-family: "LeagueSpartan-Bold";
}

.agenda-heading-img {
  width: 100%;
  height: auto;
}

.agenda-pos-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agenda-heading {
  font-family: "LeagueSpartan-ExtraBold";
  color: #000;
  position: absolute;
  font-size: 25px;
  text-transform: uppercase;
}

.agenda-icon-img {
  width: 150px;
  margin-bottom: -1rem;
}

.agenda-icon {
  text-align: center;
}

.agenda-first-row {
  position: relative;
  z-index: 1;
}

.buy-ticket-page {
  margin-top: 10%;
}

.ticket-buy-icon {
  width: 25px;
  height: auto;
  margin-right: 7px;
}

.silver-ticket-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.silver-section {
  background-image: url("../assets/images/silver-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  padding: 20px 0px;
  box-shadow: 0px 16px 80px 0px #3333331f;
}

.silver-ticket-padding {
  padding: 25px;
}

.gold-section {
  background-image: url("../assets/images/gold-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  padding: 20px 0px;
  box-shadow: 0px 16px 80px 0px #3333331f;
}

.bg-dark {
  background-color: #000 !important;
}

.diamond-section {
  background-image: url("../assets/images/diamond-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  padding: 20px 0px;
  box-shadow: 0px 16px 80px 0px #3333331f;
  /* opacity: 0.5; */
}

.position-soldout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.soldout-img {
  position: absolute;
}

.figure {
  position: relative;
  width: 20px;
  height: auto;
  max-width: 100%;
  margin-right: 6px;
}

.ticket-gold {
  margin-top: -4px;
}

.ticket-black {
  margin-top: -4px;
}

.ticket-white {
  margin-top: -1px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
  margin-top: -2px;
}

.nav-btn:hover .figure .ticket-white,
.black-btn-trans-sold:hover .figure .ticket-white,
.black-btn-trans:hover .figure .ticket-white,
.yellow-btn:hover .figure .ticket-white,
.black-btn:hover .figure .ticket-white,
.yellow-btn-award:hover .figure .ticket-white {
  opacity: 1;
}

.nav-btn:hover .figure .ticket-gold,
.black-btn-trans-sold:hover .figure .ticket-gold,
.black-btn-trans:hover .figure .ticket-gold,
.yellow-btn:hover .figure .ticket-gold,
.black-btn:hover .figure .ticket-gold,
.yellow-btn-award:hover .figure .ticket-gold {
  opacity: 0;
}

.awards-banner {
  background-image: url("../assets/images/awards-banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.award-img {
  width: 85%;
  height: auto;
}

.bg-awards-img {
  position: relative;
}

.awards-img-banner {
  position: absolute;
  z-index: 2;
  bottom: -60%;
}

.table-para-content-awards {
  color: #ffffff;
  font-size: 13px;
  padding: 30px;
  text-transform: uppercase;
  font-family: "LeagueSpartan-Bold";
  line-height: 20px;
}

.rounded-border-content-awards {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.award-categ-img {
  width: 100%;
  height: auto;
  object-fit: scale-down;
  margin-bottom: -12px;
  text-align: center;
  opacity: 0;
}

.awards-card-container:hover > .award-categ-img {
  width: 100%;
  height: auto;
  object-fit: scale-down;
  margin-bottom: -12px;
  text-align: center;
  opacity: 1;
  transition: 300ms;
}

.awards-card-container:hover > .rounded-border-content-awards {
  background-color: #fef700;
  transition: 300ms;
}

.awards-card-container:hover
  > .rounded-border-content-awards
  .table-para-content-awards {
  color: #000;
  transition: 300ms;
}

.height-award-1 .bg-awards-img {
  margin-top: 15%;
}

.height-award-1 .awards-img-banner {
  bottom: -60%;
  right: 0;
}

.height-award-1 .our-clients-container {
  background: #fff;
}

.validationmsg {
  color: red;
  padding-left: 1.5rem;
  font-size: 10px;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.tnk-head {
  display: flex;
  background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%);
  width: 100%;
  justify-content: center;
  padding: 20px;
  border-radius: 16px 16px 0px 0px;
}

.th-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%);
  padding: 50px 70px;
  border-radius: 30px;
}

.tnk-btn {
  background-color: #0b1173;
  border: none;
  width: 84%;
  margin: 20px;
  height: 52px;
  border-radius: 0px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%);
  border: 2px solid #ffc107;
}

.th-cnt > h1 {
  color: #ffffff;
  font-size: 3rem;
  margin-top: 30px;
}

.th-cnt > img {
  width: 25%;
  height: auto;
  border-radius: 8px;
}

.th-cnt > p {
  width: 80%;
  text-align: center;
  font-size: 16px;
  color: #fff;
}


.btn-anim-contact {
  position: relative;
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background-color: #000;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

.btn-contact {
  border-radius: 40px;
}

.btn-anim-contact:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

.btn-anim-contact:hover {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  background-color: #000;
}

.btn-anim-contact:before {
  /* content: ''; */
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  border: 2px solid;
  color: #ffffff00;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s;
  animation: 0.8s linear infinite rotate;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-anim-contact.sending {
  pointer-events: none;
  cursor: not-allowed;
}

.btn-anim-contact.sending:before {
  transition-delay: 0.5s;
  transition-duration: 1s;
  opacity: 1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-border {
  color: #f18301;
}

.mb-contact-form {
  margin-bottom: 50px;
}

.black-btn-trans-sold:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

.buy-ticket-page .static-header .nav-btn {
  padding: 13px 20px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 40px;
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  cursor: pointer;
  z-index: 1;
}

.buy-ticket-page .static-header .nav-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 40px;
  background-color: #00000000;
  z-index: -1;
  transition: 200ms;
}

.buy-ticket-page .static-header .nav-btn::after {
  color: rgb(0, 0, 0);
}

.buy-ticket-page .static-header .ticket-white {
  opacity: 1;
}

.nomination-container {
  padding-top: 10%;
  padding-bottom: 5%;
}

.nomination-form-field {
  margin-bottom: 30px;
}

.nomination-form-field .form-control {
  color: #ffffff;
  background-color: #fff0;
  border: 1px solid #eef0f3;
  padding: 0.65rem 0.75rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
  border: 1px solid #fef700;
}

.nomination-form-field .form-label {
  color: #fff;
  text-transform: capitalize;
}

.nomination-btn .yellow-btn {
  border-radius: 4px;
}

.nomination-btn .yellow-btn::after {
  border-radius: 4px;
}

.nomination-btn .yellow-btn:before {
  border-radius: 4px !important;
}

.nomination-btn .yellow-btn:hover {
  color: #ffc107;
}

.trading-radio .rounded-check {
  width: 100%;
  height: 55px;
  background-color: #fef700;
  border-radius: 4px;
  margin: auto;
  padding: 15px 10px 15px 35px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
}

.trading-radio .rounded-notcheck {
  width: 100%;
  height: 55px;
  background-color: #000000;
  border: 1px solid #fff;
  border-radius: 4px;
  margin: auto;
  margin-bottom: 25px;
  padding: 15px 10px 15px 35px;
  box-shadow: 0px 4px 25px 0px #0000001a;
  transition-duration: 0.5s;
  transition-property: transform, box-shadow;
  box-shadow: 0px 4px 25px 0px #0000001a;
  transition-duration: 0.5s;
  transition-property: transform, box-shadow;
  transform: none;
}

.trading-radio .rounded-notcheck .form-check-label {
  color: #fff;
  font-size: 14px;
}

.trading-radio .rounded-check .form-check-label {
  font-size: 14px;
}

/* .form-check-input {
  accent-color: #FEF700;
} */
.form-check {
  padding-left: 0;
}

.form-check-input:focus {
  border-color: #fdfdfd00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ffffff00;
}

.form-check-input:checked {
  background-color: #00000000;
  border-color: #000000;
  border: 2px solid #000;
}

.form-check-input:checked[type="radio"] {
  background-image: url("../assets/images/tick.webp");
}

.nomination-form-container .validationmsg {
  color: red;
  padding-left: 0px;
}

.form-check-input[type="radio"] {
  border-radius: 20%;
}

.form-check-input {
  background-color: #fff0;
  border: 1px solid rgb(255 255 255);
}

.organizer-card {
  border-radius: 53px;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 15px;
  transition: 300ms;
  transition-timing-function: ease;
  margin-bottom: 30px;
  background-color: #000;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.organizer-card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.organizer-card img {
  border-radius: 50px;
}

.organizer-social-media {
  background-color: #ffffff;
  padding: 13px 15px 8px 15px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: -5%;
  position: relative;
  z-index: 10;
  border-radius: 50px;
}

.organizer-social-media .speaker-white-text {
  margin-bottom: 0px;
  color: #000000;
  font-family: "LeagueSpartan-Bold";
}

.organizer-social-media .sm-icon {
  color: #000000;
  padding: 5px;
  font-size: 14px;
}

.organizer-card .speaker-job {
  font-family: "LeagueSpartan-SemiBold";
  text-align: center;
  color: #ffffff;
  margin-bottom: 3px;
  font-size: 12px;
}

.organizer-card .speaker-name {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-family: "LeagueSpartan-ExtraBold";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

img.bg-award-eagle {
  /* height: 10rem;
  margin-top: -2rem;
  width: 100%;
  object-fit: fill; */
  height: auto;
  margin-top: -2rem;
  width: 100%;
  object-fit: inherit;
}

.ab-eagle {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  text-align: center;
}

.ab-eagle-2 {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  text-align: center;
}

.icon-key {
  width: 17px;
  height: auto;
}

.input-field-text {
  background: #000;
  border: 1px solid #fff0;
  padding-left: 12px;
  width: 90%;
  color: #fff;
}

.input-field-text:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.captchabox {
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  padding: 0.9rem 0.75rem 0rem;
  text-align: center;
  width: 95%;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.captchasize {
  font-size: 17px;
  padding-left: 10px;
}

.captchbutton {
  cursor: pointer;
}

.bk-ticket .client-img {
  margin: 5px 10px;
  width: 250px !important;
  height: auto;
}

.hm-sec .our-clients-container-bg {
  height: 100px;
}

.form-control:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.thankyou-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-count-section .single-box .counter-text {
  font-size: 20px;
}

.day-count-section .single-box .gradient-text {
  font-size: 40px;
}

.speaker-content-height {
  height: 215px;
}

.digital-summit-section .para-content-white {
  font-size: 18px;
}

.organizer-card .speaker-content-bg {
  padding: 15px 15px 25px;
}

.gold-section p,
.silver-section p,
.diamond-section p {
  font-size: 14px;
}

.gold-section h6,
.silver-section h6,
.diamond-section h6 {
  font-size: 13px;
}

.nomination-form-field .form-select {
  color: #ffffff !important;
  background-color: #000;
}

.form-control {
  font-size: 14px;
}

.text-early-bird {
  color: #fff;
  font-family: "LeagueSpartan-Bold";
  font-size: 16px;
  position: fixed;
  left: 10;
}

.buy-ticket-page .day-count-section {
  bottom: auto;
  top: 0;
}

.buyticket-modal-content .Modal-View {
  width: 40%;
}

.buyticket-modal-content .modal-content {
  background: linear-gradient(90deg, #fce701 0%, #f18301 100%);
}

.buyticket-modal-content .th-cnt {
  background: #ffffff00;
}

.buyticket-modal-content .close-btn > i {
  color: #ffc107;
  background: #000000;
  border-radius: 50%;
}

.buyticket-modal-content .close-btn > i:hover {
  color: #ffc107;
  background-color: #ffffff;
}

.buyticket-modal-content .pws-form {
  border-radius: 6px;
}

.buyticket-modal-content .close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
}

.buyticket-modal-content .validationmsg {
  color: #690505;
  padding-left: 0px;
  font-size: 10px;
}

.buyticket-modal-content .pws-form {
  padding: 0.375rem 0.875rem;
}

.pws-form:focus {
  color: #000000;
  border-color: #00000000;
  box-shadow: 0px 0px 4px 0px #a0a0a0;
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}

.modal-content .yellow-btn {
  padding: 13px 35px 10px;
}

.accordion-item:first-of-type {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-bottom: 15px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgb(0, 0, 0);
  border-radius: 1rem;
  margin-bottom: 15px;
}

.accordion-button {
  background-color: #fff0;
  border: 1px solid rgb(0 0 0 / 0%);
  font-family: "LeagueSpartan-Bold";
  font-size: 15px;
}

.accordion-body {
  font-size: 14px;
  background-color: #000;
  color: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.accordion-button:focus {
  border-color: #86b7fe00;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgb(0 0 0);
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #000000;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
  border-bottom: 1px solid #969494;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../assets/images/arrow-accordian.png");
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 51px;
  right: 25px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
/* Chrome, Safari, Edge, Opera */
.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-control[type=number] {
  -moz-appearance: textfield;
}
.na-content{
  color: #fff;
  font-family: "LeagueSpartan-Bold";
}



/* last changes */
.sponsers-img-modal{
  width:100%;
  height:auto;
}
.sponsors-logo{
  padding: 20px;
}
.buy-ticket-page {
  margin-top: 0%;
}
.close-btn > i {
  font-size: 2rem;
  color: #ffc107;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
}

.close-btn > i:hover {
  background-color: #ffc107;
  color: white;
}

.close-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 4px;
  position: absolute;
  top: -15px;
  right: -15px;
}
.sponsors-modal-content{
  width: 60%;
  margin: auto;
}
.sold-out-modal-img{
  width: 200px;
  height: auto;
}
.buyticket-modal-content .modal-content {
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
}