@media screen and (max-width:600px) {
    .bg-text-stroke {
        font-size: 2em;
        -webkit-text-stroke: 1px rgb(239 237 237);
    }

    .influence-heading {
        font-size: 2em;
    }

    .count-colun {
        display: none;
    }

    .flex-align-center {
        justify-content: center;
    }

    .fs-partner {
        font-size: 28px;
    }

    .banner-para-content {
        font-size: 14px;
    }

    .gradient-text-heading {
        font-size: 25px;
        top: 16%;
    }

    .significance-section {
        margin-bottom: 0px;
    }

    .our-clients-container2 {
        height: auto;
        padding: 18px;
        rotate: 0deg;
        width: 100%;
        margin-left: 0;
    }

    .our-clients-container3 {
        height: auto;
        rotate: 0deg;
        width: 100%;
        margin-left: 0%;
        padding: 18px;
    }

    .three-deg {
        flex-direction: column-reverse;
    }

    .scroll-rotate-overflow {
        padding: 0;
    }

    .sign-right {
        right: 0;

    }

    .sign-img {
        width: 100%;
        top: 20px;
        position: relative;
    }

    .gradient-text-heading-yellow {
        font-size: 16px;
        top: 25%;
    }

    .stroke-text-container {
        margin-bottom: 30px;
    }

    .card-img-bg {
        transition: none;
    }

    .card-img-bg:hover {
        transform: none;
    }

   

    .sub-heading-attend {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .speaker-content-height {
        height: auto;
    }

    .ahy-attent-heading-2 {
        font-size: 22px;
        text-align: center;
        display: none;
    }

    .why-attend-section:hover>.row .col-lg-4 .ahy-attent-heading {
        transform: none;
        font-size: 22px;
    }

    .why-attend-section:hover>.row .col-lg-8 .why-attend-card-1 .overlay-why-attend .text-p {
        transform: none;
    }

    .why-attend-card-1 .why-attend-img-1 {
        transform: none;
        opacity: 0.3;
        margin-left: 0;
        width: auto;
        transform: scale(1.2);
    }

    .text-p {
        transform: translate3d(0%, 0, 0);
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        font-size: 13px;
    }

    .hilton-black {
        font-size: 45px;
    }

    .hilton-stroke {
        font-size: 45px;
    }

    .why-attend-card-1 {
        cursor: pointer;
        background: radial-gradient(#111 50%, #000 100%);
        border-radius: 20px;
    }

    .why-attend-section:hover>.row .col-lg-8 .why-attend-card-container {
        background-image: none;
    }

    .why-attend-section .row .col-lg-4 .ahy-attent-heading {
        background: linear-gradient(188deg, #fce701 0%, #f18301 100%);
        -webkit-background-clip: text;
        color: transparent;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transform: none;
        position: relative;
        text-align: center;
        font-size: 22px;
    }

    .text-p-2 {
        transform: translate3d(0%, 0, 0);
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        font-size: 13px;
    }

    .why-attend-card-1 .why-attend-img-2 {
        transform: none;
        opacity: 0.3;
        margin-left: 0;
        width: auto;
    }

    .ahy-attent-heading-222 {
        color: #000000 !important;
        text-transform: capitalize;
        opacity: 1 !important;
        transform: translate3d(0, 0, 0) !important;
        transform: none;
        position: relative;
        text-align: center;
        font-size: 22px;
    }

    .reverse-content {
        flex-direction: column-reverse;
    }

    .overlay-why-attend {
        padding: 15px;
    }

    .why-attend-section:hover>.row .col-lg-4 .ahy-attent-heading-22 {
        display: none;
    }

    .why-attend-section:hover>.row .col-lg-8 .why-attend-card-1 .why-attend-img-1 {
        margin-left: 0px;
    }

    .hilton-section {
        background-size: cover;
    }

    .hilton-img {
        position: relative;
        left: auto;
        bottom: auto;
        width: 100%;
        height: auto;

    }

    .wca-card-bg-yellow {
        height: auto;
        padding: 5% 5%;
    }

    .wca-img-anim {
        height: auto;
        position: relative;
        right: auto;
        bottom: auto;
        margin-bottom: -29px;
    }

    .wca-card-bg-black {
        height: auto;
        padding: 5%;
        position: relative;
    }

    .element-bg {
        padding-bottom: 40% !important;
        padding-top: 40% !important;
        margin-top: 0px !important;
    }

    .influece-img {
        width: auto;
        height: 10rem;
    }

    .inspire-container {
        padding: 50px 0px 70px;
    }

    .footer-se-logo {
        width: 100%;
        height: auto;
        position: relative;
        right: auto;
        top: auto;
    }

  

    .date-location-container {
        bottom: 0px;
        margin-top: 10%;
        position: relative;
    }

    .card-content {
        width: auto;
        height: auto;
    }

    .static-header {
        padding: 7px;
    }

    .navbar {
        padding-top: 0;
    }

    .nav-btn-container {
        margin-left: 0px;
    }

    .agenda-table-overflow {
        overflow-x: auto;
    }

    .bg-awards-img .attend-card-text-white {
        font-size: 12px;
        padding-left: 4rem;
    }

    .awards-img-banner {
        bottom: 0;
    }

    .award-categ-img {
        margin-bottom: 0px;
        opacity: 1;
    }

    .awards-card-container:hover>.award-categ-img {
        margin-bottom: 0px;
        ;
    }

    .awards-card-container {
        margin-bottom: 10px;
    }

    .table-para-content-awards {
        font-size: 11px;
        padding: 15px;
    }

    .nomination-form-field .form-label {
        font-size: 12px;
        line-height: 19px;
    }

    .height-award-1 .attend-card-text-black {
        font-size: 14px;
        padding-right: 20px;
    }

    .nomination-container .gradient-text {
        font-size: 18px;
    }

    .height-award-1 .awards-img-banner {
        bottom: 0;
    }

    .awards-banner {
        height: 25rem;
    }

    .agenda-banner {
        height: 25rem;
    }

    .partnerwithus-banner {
        height: 25rem;
    }

    .partnerwithuscontent {
        height: auto;
    }

    .partnerwithus-pos {
        position: relative;
        top: 0;
    }

    .Modal-View {
        padding: 14px;
    }

    .th-cnt>h1 {
        text-align: center;
    }

    .th-cnt {
        padding: 50px 40px;
    }

    .mb-contact-form {
        margin-bottom: 30px;
    }

    .buy-ticket-page {
        margin-top: 35%;
    }

    .silver-section {
        margin-bottom: 30px;
    }

    .gold-section {
        margin-bottom: 30px;
    }

    .award-img {
        width: 110px;
        height: auto;
    }

    .figure {
        display: none;
    }

    .day-count-section .single-box .gradient-text {
        font-size: 15px !important;
    }

    .day-count-section .single-box .counter-text {
        font-size: 12px !important;
    }

    .single-box {
        padding: 10px 5px 10px;
    }

    .speaker-data {
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }

    .speaker-job {
        line-height: 24px;
        font-size: 15px;
    }

    .ab-eagle .attend-card-text-white,
    .ab-eagle-2 .attend-card-text-white {
        color: #ffffff;
        font-family: "LeagueSpartan-Bold";
        font-size: 9px;
        margin-bottom: 0px;
    }

    .ab-eagle {
        top: -0.7rem;
        right: 0;
        text-align: end;
        padding-right: 20px;
    }

    .ab-eagle-2 {
        top: 1rem;
        right: 0;
        text-align: end;
        padding-right: 20px;
    }

    .center-mob-btn {
        text-align: center;
    }

    .pos-ab-why .ahy-attent-heading br {
        display: none;
    }

    .pos-ab-why .ahy-attent-heading-222 br {
        display: none;
    }

    .table-para-content {
        font-size: 15px;
    }

    .trading-radio .rounded-notcheck {
        height: 100%;
    }

    .captchabox {
        padding: .65rem .25rem .4rem;
        width: 80%;
    }

    .input-field-text {
        width: 85%;
    }

    .nomination-container {
        padding-top: 30%;
    }

    .rounded-border-content-awards .table-para-content-awards {
        text-transform: capitalize;
    }

    .rounded-border-content-awards {
        height: 110px;
    }

    .yellow-btn {
        width: auto !important;
    }

    .buyticket-modal-content .Modal-View {
        width: 100%;
    }

    .bk-ticket .client-img {
        width: 100% !important;
    }
    .text-early-bird {
        font-size: 15px;
        position: static;
    }

    
   
    .speaker-card {
        margin: 5px;
        margin-bottom: 15px;
    }
   .header-banner-contain .footer-container {
        margin-bottom: 5%;
    }
    .sponsors-modal-content {
        width: 100%;
    }
    .sponsors-text{
        font-size: 16px !important;
    }
    .sponsors-logo {
        padding: 5px;
    }
    .organizer-card {
        margin: 5px;
    }
}